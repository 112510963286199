import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ClosingButton from "./ClosingButton";
import styles from "./Header.module.scss";
import ImasLogo from "../ImasLogo/ImasLogo";
import { routesList } from "../../Constants/RoutesList";

const Header = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

   const currentPath = location.pathname.replace(/\/$/, "");

   const isHomepage = !routesList.includes(currentPath);

  const stickyHeaderClass = isHomepage
    ? `${styles.stickyHeader} ${"pb-5"}`
    : `${styles.stickyHeader} ${"pb-2"}`;

  return (
    <div className={stickyHeaderClass}>
      <div className={[styles.header, "container ps-0 pe-0 col-11"].join(" ")}>
        <div
          className={`d-flex flex-row justify-content-between align-items-center ${
            isHomepage ? "pt-5" : "pt-5"
          }`}
        >
          <Link to={"/"} className="d-flex align-items-center">
            <ImasLogo fill={isHomepage ? "#ffffff" : "#000000"} />
          </Link>
          <div>
            {isHomepage ? (
              <Link
                to="/contacts"
                onClick={handleToggle}
                className={[
                  styles.btnBrand,
                  "font-small-medium fw-bold text-decoration-none d-flex align-items-center justify-content-center",
                ].join(" ")}
              >
                {isOpen ? "Close" : "Get in touch"}
              </Link>
            ) : (
              <Link
                to="/"
                className={[
                  styles.closingBtn,
                  "font-small-medium fw-bold text-decoration-none d-flex align-items-center justify-content-center",
                ].join(" ")}
              >
                <ClosingButton />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
