import { useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import { officesData } from "../../Constants/OfficeData";
import { mediaList } from "../../Constants/MediaList";

const Footer = () => {
  const [vilniusTime, setVilniusTime] = useState(null);

  useEffect(() => {
    const fetchVilniusTime = async () => {
      try {
        const response = await fetch(
          "https://worldtimeapi.org/api/timezone/Europe/Vilnius"
        );
        const data = await response.json();
        setVilniusTime(new Date(data.utc_datetime));
      } catch (error) {
        console.error("Error fetching Vilnius time:", error);
      }
    };

    const intervalId = setInterval(fetchVilniusTime, 60000);
    fetchVilniusTime();

    return () => clearInterval(intervalId);
  }, []);

  const formattedVilniusTime = vilniusTime
    ? vilniusTime.toLocaleTimeString("en-US", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
        timeZone: "Europe/Vilnius",
      })
    : "Loading...";

  return (
      <footer id="footer" className={styles.footer}>
          <div className="container">
              <div className={["row mb-5 mb-lg-12", styles.footerTop].join(" ")}>
                  <div className="col-11 col-lg-8 col-xl-6 mb-5 mb-lg-8">
                      <div className="d-flex flex-column">
                          <div className="get-in-touch font-extra-large mb-6 mb-lg-8">
                              Get in touch
                          </div>
                          <div className="font-small mb-6 mb-md-0">
                              Drop us a line at{" "}
                              <a
                                  className={[styles.contact, "text-decoration-none"].join(" ")}
                                  href="mailto:info@imas.lt"
                              >
                                  info@imas.lt
                              </a>{" "}
                              or call{" "}
                              <a
                                  className={[styles.contact, "text-decoration-none"].join(" ")}
                                  href="tel:+37066240099"
                              >
                                  +370 662 400 99
                              </a>
                          </div>
                      </div>
                  </div>

                  {officesData.map((office, index) => (
                      <div
                          key={index}
                          className="d-flex flex-column col-6 col-md-4 col-lg-2 col-xl-3 mt-lg-6"
                      >
                          <div className="office mb-8 mb-md-0">
                              <div className="city font-medium mb-4">{office.city}</div>
                              <div className="address d-flex flex-column font-extra-small">
                                  <ul className="mb-4 list-unstyled">
                                      <li>{office.street}</li>
                                      <li>
                                          <a
                                              href={`tel:${office.tel}`}
                                              className="text-decoration-none text-black"
                                          >
                                              {office.tel}
                                          </a>
                                      </li>
                                      <li>{formattedVilniusTime}</li>
                                  </ul>
                              </div>
                              <a
                                  className={[
                                      styles.directions,
                                      "font-extra-small fw-bold text-decoration-none",
                                  ].join(" ")}
                                  href={office.directions}
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >
                                  Get directions
                              </a>
                          </div>
                      </div>
                  ))}
              </div>
              <div className="row">
                  <div className="col-12 col-sm-6 col-md-8 col-lg-4 col-xl-6 d-flex order-lg-2">
                      <ul className="d-flex mb-4 list-unstyled">
                          {mediaList.map((mediaItem, index) => (
                              <li
                                  key={index}
                                  className={[styles.mediaChild, "me-3 me-lg-8 me-xl-15"].join(" ")}
                              >
                                  <a
                                      className={[
                                          styles.media,
                                          "text-decoration-none font-extra-small ps-0 p-1",
                                      ].join(" ")}
                                      href={mediaItem.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                  >
                                      {mediaItem.media}
                                  </a>
                              </li>
                          ))}
                      </ul>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-8 col-xl-6 font-extra-small mb-5 order-lg-1">
                      © 2024 Imas agency.
                  </div>
              </div>
          </div>
      </footer>
  );
};

export default Footer;
