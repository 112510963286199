const LavangoLogo = ({ fill = "black" }) => {
  return (
    <svg
      width="100px"
      height="15px"
      viewBox="0 0 100 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>47B0E149-A61A-4746-8FF7-B246A13A4836</title>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="clients-modal"
          transform="translate(-415, -1348)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-8-Copy" transform="translate(415, 1290)">
            <g id="Group" transform="translate(0, 58)">
              <path
                d="M67.8465865,10.6356568 L61.4039925,1.45696482 C61.1960957,1.16094629 60.9198191,0.919394507 60.598875,0.752773795 C60.2773795,0.586147568 59.9211426,0.49935756 59.5593912,0.499751605 L57.5620382,0.499751605 L57.5620382,12.357781 L59.5593912,12.357781 L59.5593912,2.99112165 L65.477556,11.3918054 C65.68821,11.6895335 65.9672439,11.9325025 66.2914966,12.1004742 C66.615198,12.2685012 66.9747436,12.3567332 67.3392522,12.357781 L69.8439396,12.357781 L69.8439396,0.517236682 L67.8465865,0.517236682 L67.8465865,10.6356568 Z"
                id="Path"
              ></path>
              <path
                d="M82.4870409,10.4563803 L79.5147237,10.4563803 C78.5116356,10.4641006 77.5394287,10.1127716 76.7729128,9.46581008 C76.0063968,8.81890372 75.4968567,7.91910224 75.3363847,6.92913863 C75.2569758,6.33561266 75.3055035,5.73199515 75.4792103,5.15899415 C75.6529172,4.58598213 75.9473916,4.05688761 76.343333,3.60742252 C76.7387228,3.15795191 77.2262049,2.79856071 77.7726922,2.55349068 C78.3191794,2.30842065 78.9114371,2.18336826 79.5103121,2.18678174 L82.4909011,2.18678174 L83.3175251,0.337924892 L79.1127164,0.351037278 C77.5609353,0.33906088 76.0615419,0.91381383 74.9156281,1.96017426 C73.7697143,3.00653469 73.0611007,4.44725378 72.9320613,5.99376861 C72.8725047,6.80605492 72.9811404,7.62192566 73.2507996,8.39042682 C73.5204588,9.15898313 73.945627,9.86373663 74.4998346,10.4607919 C75.0534907,11.0578471 75.7246057,11.5343554 76.4712694,11.8607037 C77.2173817,12.1871071 78.0224992,12.3562369 78.8369913,12.3576707 L84.4799824,12.3576707 L84.4799824,6.86792765 L82.4826293,6.86792765 L82.4870409,10.4563803 Z"
                id="Path"
              ></path>
              <path
                d="M93.4041028,0.00142221223 C89.6233594,0.00142221223 86.6295357,2.93423955 86.6295357,6.43525973 C86.6295357,9.93630749 89.6233594,12.6986324 93.4041028,12.6986324 C97.1848461,12.6986324 100,9.93189589 100,6.43525973 C99.9966913,5.57758906 99.822433,4.72918275 99.4877027,3.93949487 C99.1529723,3.14980699 98.6643873,2.43465865 98.0506231,1.83577258 C97.4368589,1.236881 96.709496,0.766240212 95.9120988,0.451299217 C95.1141502,0.136359325 94.261608,-0.0165730674 93.4041028,0.00142221223 Z M93.4041028,10.7929856 C92.2339252,10.7516268 91.1238557,10.2623249 90.3038491,9.4261608 C89.4838425,8.58994155 89.0167641,7.47077313 88.9985662,6.29976839 C88.9985662,5.13128378 89.4623359,4.01065402 90.28896,3.1844105 C91.1150325,2.35816698 92.2355796,1.89398919 93.4041028,1.89398919 C94.572626,1.89398919 95.693173,2.35816698 96.5197971,3.1844105 C97.3458696,4.01065402 97.8101908,5.13128378 97.8101908,6.29976839 C97.7914415,7.47077313 97.3243631,8.58994155 96.5043565,9.4261608 C95.6843498,10.2623249 94.5742804,10.7516268 93.4041028,10.7929856 Z"
                id="Shape"
              ></path>
              <path
                d="M6.77478769,14.3377633 L10.4899636,14.3377633 L11.3641226,12.357781 L6.77478769,12.357781 C5.60477556,12.3175802 4.49498732,11.8292158 3.67494761,10.9937135 C2.85491342,10.1582111 2.38735524,9.03948384 2.36898643,7.86897541 L2.36898643,0.517241645 C1.59611228,0.286948274 0.772868645,0.286948274 0,0.517241645 L0,8.07003419 C0,11.5666703 2.99401125,14.3377633 6.77478769,14.3377633 Z"
                id="Path"
              ></path>
              <path
                d="M16.6319069,0.517141833 L11.3650601,12.3576707 L13.9001323,12.3576707 L18.0655123,2.99539539 L23.4678504,12.3576707 L26.2258189,12.3576707 L19.4117128,0.517141833 C18.4993934,0.286981912 17.5442263,0.286981912 16.6319069,0.517141833 Z"
                id="Path"
              ></path>
              <path
                d="M37.4944304,0.517119223 L33.3246939,9.87504136 L27.9223558,0.517119223 L25.1556193,0.517119223 L31.9784383,12.3576707 C32.8911437,12.5849233 33.8456491,12.5849233 34.7582993,12.3576707 L40.0295026,0.517119223 L37.4944304,0.517119223 Z"
                id="Path"
              ></path>
              <path
                d="M45.5055145,0.517167751 L40.2343112,12.3577258 L42.7693835,12.3577258 L46.939175,2.99542296 L52.3415132,12.3577258 L55.1082497,12.3577258 L48.2853755,0.517167751 C47.3732216,0.285537112 46.4176685,0.285537112 45.5055145,0.517167751 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LavangoLogo;
