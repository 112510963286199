import styles from "../ServicesPageActivitiesComponent/ServicesPageActivitiesComponent.module.scss";
import { activitiesList } from "../../Constants/ServiceActivitiesList";

const ServicesPageActivitiesComponent = () => {
  return (
    <div className="row col-11 col-md-12 gap-3 offset-1 mb-8 mb-md-12 mb-lg-16">
      {activitiesList.map((activity) => (
        <div
          className={[
            styles.activity,
            "col-11 col-md-5 col-lg-4 ps-4 ps-md-3 ps-lg-8 pt-10 pb-8",
          ].join(" ")}
          key={activity.name}
        >
          <h2 className="mb-8">{activity.name}</h2>
          <ul className="list-unstyled">
            {activity.technologies.map((technology, index) => (
              <li key={index} className="font-small fw-normal">
                {technology}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ServicesPageActivitiesComponent;
