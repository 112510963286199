// Services.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Services.module.scss";
import Cursor from "../Cursor/Cursor";
import { servicesRoutesList } from "../../Constants/ServicesRoutesList";

const Services = () => {
  const [showCursor, setShowCursor] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const handleLinkMouseEnter = () => {
    setShowCursor(true);
    setOpacity(1);
  };

  const handleLinkMouseLeave = () => {
    setShowCursor(false);
    setOpacity(0);
  };

  return (
    <div className={[styles.services, "container"].join(" ")}>
      <div className="row">
        <div className="d-flex flex-column col-12 col-md-6 col-xl-5">
          <div className="font-large mb-8 mt-3">Services</div>
          <div
            className={[styles.servicesContent, "font-small fw-normal"].join(
              " "
            )}
          >
            Project planning and needs analysis, interactive prototype and
            design development, programming of web solutions and mobile
            applications, testing, and software maintenance – these are our
            provided services.
          </div>
        </div>
        <div className="services-programs d-flex flex-column col-12 col-md-6 col-xl-7">
          <ul className="list-unstyled">
            {servicesRoutesList.map((service, index) => (
              <li key={index}>
                <Link
                  to={`/services/${service.route}`}
                  className={[
                    styles.service,
                    "font-medium text-decoration-none text-white ",
                  ].join(" ")}
                  onMouseEnter={handleLinkMouseEnter}
                  onMouseLeave={handleLinkMouseLeave}
                >
                  {service.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <Cursor opacity={opacity} />
      </div>
    </div>
  );
};

export default Services;
