import { Helmet } from "react-helmet-async";
import Clients from "../../components/Clients/Clients";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import HeroSection from "../../components/HeroSection/HeroSection";
import Projects from "../../components/Projects/Projects";
import Services from "../../components/Services/Services";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Internet solutions and mobile applications - Imas.lt</title>
        <meta
          name="description"
          content="Internet solutions and mobile applications. We specialize in crafting unique online projects, serving as idea generators and goal achievers."
        ></meta>
      </Helmet>
      <div className="bg-black">
        <Header />
        <HeroSection />
        <Services />
        <Projects />
        <Clients />
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
