const KiloHealthLogo = ({ fill = "black" }) => {
  return (
    <svg
      width="120px"
      height="22px"
      viewBox="0 0 120 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>631F7448-2255-4473-80EB-5BF3BE112FEA</title>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="1"
      >
        <g
          id="landing"
          transform="translate(-518, -2711)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-11" transform="translate(100, 2657)">
            <g id="Group-8-Copy-2" transform="translate(418, 0)">
              <g id="Group" transform="translate(0, 54)">
                <path
                  d="M17.2575882,0 L3.94995882,0 C1.77103235,0.00618936176 0.00619898824,1.76827941 0,3.94382647 L0,17.2326176 C0.00619898824,19.4082353 1.77103235,21.1702941 3.94995882,21.1764706 L17.2575882,21.1764706 C19.4364706,21.1702941 21.2013529,19.4082353 21.2075294,17.2326176 L21.2075294,3.94946471 C21.2044412,1.77171176 19.4386765,0.00620221765 17.2575882,0 Z M2.19128824,3.94382647 C2.19232941,2.97478235 2.97940588,2.18976176 3.94995882,2.18976176 L7.95070588,2.18976176 L2.19128824,7.93836176 L2.19128824,3.94382647 Z M2.19128824,11.0427353 L11.0598529,2.18976176 L17.4550588,2.18976176 L2.18564118,17.4354706 L2.19128824,11.0427353 Z M3.74305588,18.9867353 L5.84782059,16.8889412 L7.95070588,18.9867353 L3.74305588,18.9867353 Z M11.058,18.9867353 L7.40147647,15.3358235 L10.5990882,12.1432059 L17.4645,18.9904412 L11.058,18.9867353 Z M19.0162059,17.4373235 L12.1583824,10.5938824 L19.0162059,3.76541471 L19.0162059,17.4373235 Z"
                  id="Shape"
                ></path>
                <polygon
                  id="Path"
                  points="30.6583235 16.9034118 30.6583235 4.52985882 33.2253529 4.52985882 33.2253529 10.0802647 38.6428235 4.52985882 41.7764118 4.52985882 35.7924706 10.4160882 42.0596471 16.9034118 38.6250882 16.9034118 33.2253529 11.1054706 33.2253529 16.9034118"
                ></polygon>
                <path
                  d="M44.0472353,6.59800588 C43.6695,6.59800588 43.3449706,6.46248529 43.0734706,6.19144412 C42.8020588,5.92041176 42.6663529,5.59634118 42.6663529,5.21924118 C42.6663529,4.84214118 42.8020588,4.51807059 43.0734706,4.24703824 C43.3449706,3.97599706 43.6695,3.84047647 44.0472353,3.84047647 C44.4248824,3.84047647 44.7495,3.97599706 45.0209118,4.24703824 C45.2924118,4.51807059 45.4281176,4.84214118 45.4281176,5.21924118 C45.4281176,5.59634118 45.2924118,5.92041176 45.0209118,6.19144412 C44.7495,6.46248529 44.4248824,6.59800588 44.0472353,6.59800588 Z M45.2687647,7.69395 L45.2687647,16.9034118 L42.8433529,16.9034118 L42.8433529,7.69395 L45.2687647,7.69395 Z"
                  id="Shape"
                ></path>
                <polygon
                  id="Path"
                  points="49.5910588 3.99956471 49.5910588 16.9034118 47.1656471 16.9034118 47.1656471 4.52985882"
                ></polygon>
                <path
                  d="M51.063,12.3075 C51.063,11.6357647 51.1868824,11.0112353 51.4347353,10.4338235 C51.6944118,9.84458824 52.0425882,9.33785294 52.4792647,8.91361765 C52.9277647,8.47760294 53.4530294,8.13585882 54.0549706,7.88838529 C54.6569118,7.64092059 55.3001471,7.51717941 55.9846765,7.51717941 C56.6692059,7.51717941 57.3124412,7.64092059 57.9143824,7.88838529 C58.5163235,8.13585882 59.0356765,8.47760294 59.4723529,8.91361765 C59.9208529,9.33785294 60.2690294,9.84458824 60.5168824,10.4338235 C60.7765588,11.0112353 60.9063529,11.6357647 60.9063529,12.3075 C60.9063529,12.9792353 60.7765588,13.6096765 60.5168824,14.1989118 C60.2690294,14.7763235 59.9208529,15.2771471 59.4723529,15.7013824 C59.0356765,16.1256176 58.5163235,16.4614412 57.9143824,16.7089412 C57.3124412,16.9564412 56.6692059,17.0801471 55.9846765,17.0801471 C55.3001471,17.0801471 54.6569118,16.9564412 54.0549706,16.7089412 C53.4530294,16.4614412 52.9277647,16.1256176 52.4792647,15.7013824 C52.0425882,15.2771471 51.6944118,14.7763235 51.4347353,14.1989118 C51.1868824,13.6096765 51.063,12.9792353 51.063,12.3075 Z M55.9846765,14.9766176 C56.6928529,14.9766176 57.2947941,14.7173824 57.7905,14.1989118 C58.2862059,13.6803529 58.5340588,13.0499118 58.5340588,12.3075 C58.5340588,11.5533529 58.2862059,10.917 57.7905,10.3984412 C57.2947941,9.87997059 56.6928529,9.62064706 55.9846765,9.62064706 C55.2765,9.62064706 54.6745588,9.87997059 54.1788529,10.3984412 C53.6831471,10.917 53.4352941,11.5533529 53.4352941,12.3075 C53.4352941,13.0499118 53.6831471,13.6803529 54.1788529,14.1989118 C54.6745588,14.7173824 55.2765,14.9766176 55.9846765,14.9766176 Z"
                  id="Shape"
                ></path>
                <path
                  d="M63.1680882,14.1989118 C63.5693824,14.1989118 63.9116471,14.3402647 64.1948824,14.6231471 C64.4781176,14.9059412 64.6198235,15.2476765 64.6198235,15.6483529 C64.6198235,16.0372059 64.4781176,16.3731176 64.1948824,16.6559118 C63.9116471,16.9387059 63.5693824,17.0801471 63.1680882,17.0801471 C62.7785294,17.0801471 62.4421765,16.9387059 62.1589412,16.6559118 C61.8757059,16.3731176 61.734,16.0372059 61.734,15.6483529 C61.734,15.2476765 61.8757059,14.9059412 62.1589412,14.6231471 C62.4421765,14.3402647 62.7785294,14.1989118 63.1680882,14.1989118 Z"
                  id="Path"
                ></path>
                <polygon
                  id="Path"
                  points="66.4293529 16.9034118 66.4293529 4.52985882 68.9963824 4.52985882 68.9963824 9.49694118 74.6970882 9.49694118 74.6970882 4.52985882 77.2641176 4.52985882 77.2641176 16.9034118 74.6970882 16.9034118 74.6970882 11.8478824 68.9963824 11.8478824 68.9963824 16.9034118"
                ></polygon>
                <path
                  d="M87.6255,15.7544118 C87.0590294,16.2140294 86.4747353,16.5498529 85.8728824,16.7619706 C85.2826765,16.9740882 84.6217941,17.0801471 83.8899706,17.0801471 C83.1936176,17.0801471 82.5385588,16.9564412 81.9248824,16.7089412 C81.3229412,16.4614412 80.7976765,16.1256176 80.3491765,15.7013824 C79.9125,15.2771471 79.5643235,14.7763235 79.3046471,14.1989118 C79.0567941,13.6096765 78.9329118,12.9792353 78.9329118,12.3075 C78.9329118,11.6475882 79.0508824,11.0288824 79.287,10.4514706 C79.5348529,9.86223529 79.8712059,9.3555 80.2960588,8.93126471 C80.721,8.49527647 81.2226176,8.15353235 81.8009118,7.90606765 C82.3910294,7.65859412 83.0166176,7.53486176 83.6775882,7.53486176 C84.3267353,7.53486176 84.9285882,7.66448824 85.4833235,7.92374118 C86.0498824,8.17121471 86.5337647,8.51295882 86.9350588,8.949 C87.3481765,9.38497059 87.6668824,9.90944118 87.8910882,10.5221471 C88.1271176,11.1232059 88.245,11.7772059 88.245,12.4842353 L88.245,13.1205882 L81.3760588,13.1205882 C81.5412353,13.6980882 81.8599412,14.1635294 82.3320882,14.5170882 C82.8041471,14.8705588 83.3588824,15.0473824 83.9962059,15.0473824 C84.3975,15.0473824 84.7752353,14.9825294 85.1292353,14.8529118 C85.4833235,14.7232941 85.7842941,14.5406471 86.0321471,14.3049706 L87.6255,15.7544118 Z M83.6244706,9.53232353 C83.0697353,9.53232353 82.5916765,9.70314706 82.1903824,10.0448824 C81.7890882,10.3748824 81.5117647,10.8226765 81.3583235,11.3883529 L85.8728824,11.3883529 C85.7194412,10.8462353 85.4361176,10.4043529 85.0230882,10.0626176 C84.6217941,9.70905882 84.1555588,9.53232353 83.6244706,9.53232353 Z"
                  id="Shape"
                ></path>
                <path
                  d="M92.6179412,17.0625 C91.6261765,17.0625 90.8179412,16.8031765 90.1923529,16.2847059 C89.5667647,15.7544118 89.2544118,15.0709412 89.2544118,14.2342059 C89.2544118,13.3621765 89.5905882,12.6787059 90.2629412,12.1837941 C90.9361765,11.6887941 91.8626471,11.4413824 93.0423529,11.4413824 C93.42,11.4413824 93.7976471,11.4708529 94.1752941,11.5297059 C94.5538235,11.5886471 94.9252941,11.6770588 95.2914706,11.7948529 L95.2914706,11.1408529 C95.2914706,10.6105588 95.1255882,10.2098824 94.7955882,9.93882353 C94.4647059,9.66785294 93.9811765,9.53232353 93.3432353,9.53232353 C92.9541176,9.53232353 92.5288235,9.59708824 92.0691176,9.72670588 C91.6208824,9.84458824 91.1073529,10.0331471 90.5285294,10.2923824 L89.6435294,8.50706471 C90.3635294,8.1771 91.0597059,7.92963529 91.7329412,7.76465294 C92.4167647,7.59967059 93.09,7.51717941 93.7508824,7.51717941 C94.9897059,7.51717941 95.9523529,7.81768235 96.6361765,8.41867941 C97.3332353,9.00794118 97.6808824,9.84458824 97.6808824,10.9287353 L97.6808824,16.9034118 L95.2914706,16.9034118 L95.2914706,16.2670588 C94.9014706,16.5381176 94.4885294,16.7384118 94.0517647,16.8680294 C93.6264706,16.9976471 93.1491176,17.0625 92.6179412,17.0625 Z M91.5732353,14.1811765 C91.5732353,14.5347353 91.7267647,14.8175294 92.0338235,15.0296471 C92.34,15.2300294 92.7476471,15.3301765 93.255,15.3301765 C93.6564706,15.3301765 94.0226471,15.2830588 94.3526471,15.1887353 C94.695,15.0826765 95.0082353,14.9354118 95.2914706,14.7468529 L95.2914706,13.3680882 C94.9958824,13.2502941 94.6835294,13.1677941 94.3526471,13.1205882 C94.0341176,13.0617353 93.6979412,13.0322647 93.3432353,13.0322647 C92.7891176,13.0322647 92.3523529,13.1383235 92.0338235,13.3504412 C91.7267647,13.5507353 91.5732353,13.8277059 91.5732353,14.1811765 Z"
                  id="Shape"
                ></path>
                <polygon
                  id="Path"
                  points="101.942647 3.99956471 101.942647 16.9034118 99.5170588 16.9034118 99.5170588 4.52985882"
                ></polygon>
                <path
                  d="M105.096176,14.3049706 L105.096176,9.72670588 L103.201765,9.72670588 L103.201765,7.69395 L105.096176,7.69395 L105.096176,5.34297353 L107.521765,4.79500588 L107.521765,7.69395 L110.141471,7.69395 L110.141471,9.72670588 L107.521765,9.72670588 L107.521765,13.8099706 C107.521765,14.2460294 107.616176,14.5523824 107.805,14.7292059 C107.993824,14.9059412 108.323824,14.9943529 108.795882,14.9943529 C109.02,14.9943529 109.232647,14.9825294 109.433824,14.9589706 C109.634118,14.9235882 109.852059,14.8647353 110.088529,14.7822353 L110.088529,16.7619706 C109.829118,16.8444706 109.515882,16.9092353 109.150588,16.9564412 C108.784412,17.0152941 108.471176,17.0447647 108.211765,17.0447647 C107.184706,17.0447647 106.406471,16.815 105.875294,16.3553824 C105.355588,15.8840294 105.096176,15.2005588 105.096176,14.3049706 Z"
                  id="Path"
                ></path>
                <path
                  d="M111.342353,16.9034118 L111.342353,4.52985882 L113.767941,3.99956471 L113.767941,8.41867941 C114.121765,8.12407059 114.511765,7.90017353 114.936176,7.74697941 C115.372941,7.59377647 115.845,7.51717941 116.352353,7.51717941 C117.414706,7.51717941 118.288235,7.85892353 118.972941,8.54242059 C119.657647,9.22588235 120,10.1038235 120,11.1762353 L120,16.9034118 L117.574412,16.9034118 L117.574412,11.5297059 C117.574412,10.9522941 117.397059,10.4868529 117.043235,10.1332941 C116.700882,9.77973529 116.240294,9.603 115.662353,9.603 C115.260882,9.603 114.894706,9.6855 114.564706,9.8505 C114.246176,10.0036765 113.980588,10.2276176 113.767941,10.5221471 L113.767941,16.9034118 L111.342353,16.9034118 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default KiloHealthLogo;
