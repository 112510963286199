import styles from "./ClientsPageClientsList.module.scss";
import { clientsList } from "../../../Constants/ClientsList";

const ClientsPageClientsList = () => {
  return (
    <div className="container">
      <div className={[styles.clientsSpace, "row mb-8 offset-1"].join(" ")}>
        {clientsList.map((client) => (
          <div
            key={client.alt}
            className={[
              styles.logo,
              "col-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-start pt-8 pb-8 ps-0",
            ].join(" ")}
          >
            <a href={client.url} target="blank">
              {client.logo}
            </a>
          </div>
        ))}
        <div
          className={[
            styles.clientsButton,
            "col-6 col-md-4 col-lg-3 d-flex align-items-center justify-content-start justify-content-sm-start ps-0 pt-8 pb-8",
          ].join(" ")}
        >
          {" "}
          +50 more
        </div>
      </div>
    </div>
  );
};

export default ClientsPageClientsList;
