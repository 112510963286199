const JuodeliaiLogo = ({ fill = "black" }) => {
  return (
    <svg
      width="70px"
      height="58px"
      viewBox="0 0 70 58"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>9B632D9A-26EF-482D-8DC3-DAA0D7FD3D1D</title>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="1"
      >
        <g
          id="landing"
          transform="translate(-727, -2843)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-11" transform="translate(100, 2657)">
            <g id="Group-8" transform="translate(627, 150)">
              <g id="Group" transform="translate(0, 36)">
                <path
                  d="M29.533,15.8340642 L20.958,18.6900642 L20.958,10.1850642 L21.021,10.1150642 L21.084,10.0520642 C23.184,7.82606422 26.369,6.34206422 29.484,7.17506422 L29.54,7.17506422 L29.54,15.8340642 L29.533,15.8340642 Z"
                  id="Path"
                ></path>
                <path
                  d="M48.818,24.6610642 L48.818,6.42215e-05 L39.718,3.04506422 L39.718,26.1030642 C39.8093668,27.7187535 39.4038592,29.3236832 38.556,30.7020642 C37.7766665,31.8080642 36.4653334,32.3610642 34.622,32.3610642 C32.7786667,32.3610642 31.4673333,31.8080642 30.688,30.7020642 C29.8401407,29.3236832 29.4346333,27.7187535 29.526,26.1030642 L29.526,20.0130642 L20.958,22.8620642 L20.958,24.6610642 C20.958,29.4630642 21.721,33.1100642 24.269,35.6020642 C26.817,38.0940642 29.533,39.8580642 34.629,39.8580642 C39.725,39.8580642 42.7,38.1780642 45.22,35.7000642 C47.74,33.2220642 48.818,29.4630642 48.818,24.6610642 L48.818,24.6610642 Z"
                  id="Path"
                ></path>
                <path
                  d="M1.736,55.0760642 C1.736,55.9580642 1.316,56.0770642 0.91,56.0770642 C0.814667637,56.0883748 0.718332363,56.0883748 0.623,56.0770642 L0,55.8810642 L0,57.5330642 L0.259,57.5330642 L0.721,57.5890642 C0.886521426,57.5988747 1.05247857,57.5988747 1.218,57.5890642 C1.55424541,57.5947839 1.88799719,57.5304175 2.198,57.4000642 C2.47390494,57.2804465 2.71480684,57.092543 2.898,56.8540642 C3.08433273,56.6156397 3.22009768,56.3417279 3.297,56.0490642 C3.38382616,55.7345122 3.42623687,55.4093636 3.423,55.0830642 L3.423,48.7830642 L1.736,48.7830642 L1.736,55.0760642 Z"
                  id="Path"
                ></path>
                <path
                  d="M11.585,54.4250642 C11.6068774,54.9030865 11.4716573,55.3751273 11.2,55.7690642 C10.9636388,56.0742201 10.5911759,56.242091 10.206,56.2170642 C9.82127531,56.2396231 9.44991117,56.0722479 9.212,55.7690642 C8.94528495,55.3734141 8.81501992,54.9015105 8.841,54.4250642 L8.841,48.8250642 L7.161,48.8250642 L7.161,54.2640642 L7.161,54.8240642 C7.16623208,54.9883178 7.18260305,55.1520272 7.21,55.3140642 C7.2380609,55.4708226 7.2778391,55.6252559 7.329,55.7760642 C7.38761373,55.9293366 7.45540299,56.0789406 7.532,56.2240642 C7.64116451,56.4223392 7.77525357,56.6058295 7.931,56.7700642 C8.10142627,56.9492695 8.29716818,57.1025614 8.512,57.2250642 C8.74849129,57.3623055 9.00293184,57.4659664 9.268,57.5330642 C9.8854966,57.6730723 10.5265034,57.6730723 11.144,57.5330642 C11.4085161,57.4675789 11.6628614,57.3663116 11.9,57.2320642 C12.3087363,56.9923342 12.6449593,56.6464355 12.873,56.2310642 C12.953677,56.0879748 13.0216057,55.9380631 13.076,55.7830642 C13.1271609,55.6322559 13.1669391,55.4778226 13.195,55.3210642 C13.2248088,55.1591976 13.2435191,54.9954827 13.251,54.8310642 C13.251,54.6630642 13.251,54.4810642 13.251,54.2710642 L13.251,48.8320642 L11.585,48.8320642 L11.585,54.4250642 Z"
                  id="Path"
                ></path>
                <path
                  d="M22.883,49.9520642 C22.5858892,49.5717322 22.2050038,49.2651073 21.77,49.0560642 C21.3089907,48.8453912 20.8068041,48.740171 20.3,48.7480642 C19.2775621,48.7016784 18.2917724,49.1337702 17.633,49.9170642 C16.944418,50.8964272 16.6132631,52.0826539 16.695,53.2770642 C16.685955,53.8805405 16.766155,54.48204 16.933,55.0620642 C17.0777748,55.5679938 17.3151212,56.0426869 17.633,56.4620642 C17.9304309,56.8448946 18.3142171,57.1519237 18.753,57.3580642 C19.209727,57.5667083 19.7069062,57.6718809 20.209,57.6660642 C21.2318423,57.7146313 22.2185575,57.2821338 22.876,56.4970642 C23.5653754,55.5106676 23.8918763,54.3159706 23.8,53.1160642 C23.8064641,52.5122593 23.7239106,51.9107979 23.555,51.3310642 C23.4175564,50.8340411 23.1897575,50.366579 22.883,49.9520642 L22.883,49.9520642 Z M20.916,56.0700642 C20.6953377,56.1669281 20.4569865,56.216982 20.216,56.2170642 C19.9384168,56.2283881 19.6642557,56.1525043 19.432,56.0000642 C19.2024875,55.8373772 19.0133117,55.6242552 18.879,55.3770642 C18.7259668,55.0795331 18.6174751,54.7611335 18.557,54.4320642 C18.4919388,54.0505016 18.4591562,53.6641338 18.459,53.2770642 C18.4488467,52.7798525 18.5029611,52.2834114 18.62,51.8000642 C18.696312,51.4582906 18.8390132,51.1348343 19.04,50.8480642 C19.1839684,50.638914 19.379193,50.4702014 19.607,50.3580642 C19.8273432,50.2602088 20.0659068,50.2101105 20.307,50.2110642 C20.5856354,50.1993651 20.8606035,50.2779275 21.091,50.4350642 C21.3184699,50.5979994 21.5053093,50.8111879 21.637,51.0580642 C21.7942181,51.3538006 21.902935,51.6728609 21.959,52.0030642 C22.1161256,52.8870792 22.0970947,53.7934238 21.903,54.6700642 C21.8223253,55.0103881 21.6799747,55.3330493 21.483,55.6220642 C21.3322075,55.8142548 21.1378574,55.9678152 20.916,56.0700642 L20.916,56.0700642 Z"
                  id="Shape"
                ></path>
                <path
                  d="M33.145,50.7500642 C32.9887328,50.4708325 32.798264,50.2121713 32.578,49.9800642 C32.1323444,49.5126826 31.5688967,49.1741304 30.947,49.0000642 C30.4544675,48.8665249 29.9451529,48.8052185 29.435,48.8180642 L27.216,48.8180642 L27.216,57.5680642 L28.889,57.5680642 C29.6413188,57.584439 30.3904866,57.4658996 31.101,57.2180642 C31.671802,57.0150303 32.1888742,56.6846786 32.613,56.2520642 C33.0004185,55.8413872 33.2899706,55.3484307 33.46,54.8100642 C33.6317605,54.2451733 33.7167295,53.6574719 33.712,53.0670642 C33.7117654,52.6400772 33.660059,52.2146749 33.558,51.8000642 C33.4653493,51.4339884 33.3265719,51.0811646 33.145,50.7500642 L33.145,50.7500642 Z M28.945,50.3090642 L29.323,50.3090642 C29.7548714,50.2974722 30.1849626,50.3687581 30.59,50.5190642 C30.8991733,50.6454583 31.1747519,50.8419578 31.395,51.0930642 C31.6039814,51.3448609 31.7567272,51.6384192 31.843,51.9540642 C31.9431281,52.3165029 31.9925977,52.6910582 31.99,53.0670642 C32.0369086,53.878325 31.7882213,54.6790984 31.29,55.3210642 C30.7795923,55.8677054 30.0474574,56.1512786 29.302,56.0910642 L28.924,56.0910642 L28.945,50.3090642 Z"
                  id="Shape"
                ></path>
                <polygon
                  id="Path"
                  points="38.759 53.7810642 41.615 53.7810642 41.615 52.3110642 38.759 52.3110642 38.759 50.3020642 41.755 50.3020642 41.755 48.8320642 37.065 48.8320642 37.065 57.5610642 41.923 57.5610642 41.923 56.0910642 38.759 56.0910642"
                ></polygon>
                <polygon
                  id="Path"
                  points="46.914 48.8320642 45.213 48.8320642 45.213 57.5610642 49.805 57.5610642 49.805 56.0910642 46.914 56.0910642"
                ></polygon>
                <polygon
                  id="Path"
                  points="52.878 48.8320642 54.579 48.8320642 54.579 57.5610642 52.878 57.5610642"
                ></polygon>
                <path
                  d="M60.662,48.8320642 L57.491,57.5680642 L59.234,57.5680642 L59.934,55.4680642 L62.874,55.4680642 L63.574,57.5680642 L65.38,57.5680642 L62.3,48.8320642 L60.662,48.8320642 Z M62.398,53.9700642 L60.445,53.9700642 L61.432,51.1000642 L62.398,53.9700642 Z"
                  id="Shape"
                ></path>
                <polygon
                  id="Path"
                  points="68.299 48.8320642 70 48.8320642 70 57.5610642 68.299 57.5610642"
                ></polygon>
                <path
                  d="M53.522,2.83506422 C53.6796568,2.68518505 53.8078646,2.50711861 53.9,2.31006422 C53.9846527,2.10353638 54.0274798,1.88226287 54.026,1.65906422 C54.0269247,1.43818465 53.9841008,1.21930836 53.9,1.01506422 C53.812508,0.81850448 53.6917339,0.638527421 53.543,0.483064221 C53.3929161,0.33276791 53.2144595,0.213796912 53.018,0.133064221 C52.6021321,-0.0443547405 52.1318679,-0.0443547405 51.716,0.133064221 C51.521387,0.217230182 51.3435426,0.33579316 51.191,0.483064221 C51.0422661,0.638527421 50.9214919,0.81850448 50.834,1.01506422 C50.7498992,1.21930836 50.7070753,1.43818465 50.708,1.65906422 C50.7065202,1.88226287 50.7493472,2.10353638 50.834,2.31006422 C50.919856,2.50544392 51.0408633,2.68339534 51.191,2.83506422 C51.3433571,2.98436287 51.5211469,3.10525985 51.716,3.19206422 C52.1337068,3.36024605 52.6002932,3.36024605 53.018,3.19206422 C53.2072901,3.1064003 53.3783838,2.98520901 53.522,2.83506422 L53.522,2.83506422 Z M52.346,3.02406422 C52.1585627,3.02878634 51.9723165,2.99296979 51.8,2.91906422 C51.6392201,2.85345456 51.4920497,2.7585058 51.366,2.63906422 C51.115036,2.37493935 50.9769246,2.02338304 50.981,1.65906422 C50.9829474,1.47309937 51.0234686,1.28956253 51.1,1.12006422 C51.1639145,0.958407369 51.2590606,0.810930956 51.38,0.686064221 C51.5012991,0.565552172 51.6436483,0.468280102 51.8,0.399064221 C51.9663483,0.330471218 52.1451084,0.297102662 52.325,0.301064221 C52.5071743,0.296941778 52.6882524,0.330298289 52.857,0.399064221 C53.0150274,0.469013573 53.1595529,0.566153651 53.284,0.686064221 C53.4072854,0.809051281 53.502765,0.957044756 53.564,1.12006422 C53.6357223,1.29058979 53.6714682,1.47408594 53.669,1.65906422 C53.6708557,1.84625378 53.6351518,2.03191457 53.564,2.20506422 C53.5009268,2.36715216 53.4056789,2.51478656 53.284,2.63906422 C53.1592715,2.75682174 53.0147156,2.85161243 52.857,2.91906422 C52.6952135,2.98727774 52.5215754,3.02295679 52.346,3.02406422 L52.346,3.02406422 Z"
                  id="Shape"
                ></path>
                <path
                  d="M52.99,1.61706422 C53.0855182,1.51215804 53.1358223,1.37382154 53.13,1.23206422 C53.13,0.868064222 52.8966666,0.686064222 52.43,0.686064222 L51.716,0.686064222 L51.716,2.63206422 L52.031,2.63206422 L52.031,1.76406422 L52.304,1.76406422 L52.843,2.63206422 L53.2,2.63206422 L52.64,1.76406422 C52.7723392,1.76747247 52.8997826,1.7139463 52.99,1.61706422 L52.99,1.61706422 Z M52.409,1.51906422 L52.031,1.51906422 L52.031,0.931064222 L52.409,0.931064222 C52.5107251,0.927161736 52.6118058,0.948821871 52.703,0.994064222 C52.7771052,1.04324628 52.8176037,1.12964368 52.808,1.21806422 C52.8199224,1.26870528 52.8199224,1.32142316 52.808,1.37206422 C52.7924037,1.4096563 52.7654922,1.44146102 52.731,1.46306422 L52.598,1.51206422 L52.409,1.51906422 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default JuodeliaiLogo;
