import styles from "./ClientsPageFooter.module.scss";

const ClientsPageFooter = () => {
  return (
    <footer id="footer" className="bg-black text-white pt-8 pt-lg-16">
      <div className="container">
        <div className="row mb-5 mb-lg-12">
          <div className="col-12 col-lg-8 col-xl-6 mb-5 mb-lg-8">
            <div className="d-flex flex-column">
              <div className="get-in-touch font-extra-large mb-5 mb-lg-8 offset-1 offset-lg-2">
                Get in touch
              </div>
              <div className="font-small fw-normal col-10 offset-1 offset-lg-2">
                Drop us a line at{" "}
                <a
                  className={[styles.contact, "text-decoration-none"].join(" ")}
                  href="mailto:info@imas.lt"
                >
                  info@imas.lt
                </a>{" "}
                or call{" "}
                <a
                  className={[styles.contact, "text-decoration-none"].join(" ")}
                  href="tel:+37066240099"
                >
                  +370 662 400 99
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default ClientsPageFooter;
