import { useState, useEffect } from "react";
import styles from "./Cursor.module.scss";

const Cursor = ({ opacity }) => {
  const [cursorPosition, setCursorPosition] = useState({ x: -100, y: -100 });

  useEffect(() => {
    const updateCursorPosition = (e) => {
      const { clientX, clientY } = e;
      setCursorPosition({ x: clientX, y: clientY });
    };

    document.addEventListener("mousemove", updateCursorPosition);

    return () => {
      document.removeEventListener("mousemove", updateCursorPosition);
    };
  }, []);

  return (
    <div
      className={styles.cursorContainer}
      style={{
        top: cursorPosition.y,
        left: cursorPosition.x,
        opacity: opacity,
        // opacity: opacity,
      }}
    >
      <div className={styles.cursor}></div>
      <div className={styles.arrow}></div>
    </div>
  );
};

export default Cursor;
