import press from "../../assets/PressPicture.png";
import opal from "../../assets/OpalPicture.png";
import coffee from "../../assets/HuracanPicture.png";
import seb from "../../assets/SebPicture.png";
import pressLogo from "../../assets/15min.svg";
import opalLogo from "../../assets/opalLogo.svg";
import coffeeLogo from "../../assets/coffeeLogo.svg";
import sebLogo from "../../assets/sebLogo.svg";
import styles from "./Projects.module.scss";
import { Link } from "react-router-dom";

const projectsList = [
  {
    mainImage: press,
    logo: pressLogo,
    alt: "15min.",
    mainAlt: "Press",
    title: "15min",
    category: "Press",
    services: ["Research", "UI/UX", "Mobile application"],
    url: "https://15min.lt",
  },
  {
    mainImage: opal,
    logo: opalLogo,
    alt: "Opal",
    mainAlt: "Opal-transfer",
    title: "Opal Transfer",
    category: "Fintech",
    services: ["Research", "UI/UX", "Mobile application"],
    url: "https://opaltransfer.com",
  },
  {
    mainImage: coffee,
    logo: coffeeLogo,
    alt: "Coffee",
    mainAlt: "Huracan-coffee",
    title: "Huracan Coffee",
    category: "E-commerce & Retail",
    services: ["Developing", "Website"],
    url: "https://huracan.lt",
  },
  {
    mainImage: seb,
    logo: sebLogo,
    alt: "Seb",
    mainAlt: "SebBank",
    title: "Seb",
    category: "Fintech",
    services: ["Strategy", "Developing"],
    url: "https://seb.lt",
  },
];

const Projects = () => {
  return (
    <div className={[styles.projects, "container"].join(" ")}>
      <div className="d-flex flex-column d-none d-sm-block">
        <div className="font-large mb-8">Projects</div>
        <div className="font-small fw-normal mb-sm-5 mb-lg-9 mb-12 col-12 col-md-9 col-xl-6">
          The best evaluation of our work is the trust of our clients and
          long-term partnership. We set the highest standards for the quality of
          our services and successfully achieve clients' business goals.
        </div>
      </div>

      <div className="row">
        {projectsList.map((project) => (
          <div
            className="card col-12 col-sm-9 col-md-5 col-xl-3 mb-11 mb-sm-8 mb-xl-0 bg-black"
            key={project.title}
          >
            <a href={project.url} className="position-relative" target="blank">
              <img
                src={project.logo}
                alt={project.alt}
                className={[styles.projectLogo, "position-absolute"].join(" ")}
              />
              <img
                src={project.mainImage}
                className={[styles.projectImage, "card-img-top rounded-0"].join(
                  " "
                )}
                alt={project.mainAlt}
              ></img>
            </a>
            <div className="card-body ps-0 pt-3">
              <p className="card-text font-small mb-5 fw-normal d-none d-sm-block">
                {project.category}
              </p>
              <h5 className="card-title font-medium mb-1 mb-sm-2">{project.title}</h5>

              <ul className="d-flex gap-2 ps-0 mb-0 opacity-75">
                {project.services.map((service) => (
                  <li
                    key={service}
                    className="list-unstyled font-extra-small me-md-1 me-xl-2"
                  >
                    {service}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
