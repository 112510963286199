const TeliaLogo = ({ fill = "black" }) => {
  return (
    <svg
      width="80px"
      height="32px"
      viewBox="0 0 80 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>A380563A-E149-40BE-B32C-328649D9D6CC</title>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="1"
      >
        <g
          id="landing"
          transform="translate(-1146, -2706)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-11" transform="translate(100, 2657)">
            <g id="Group-8-Copy-4" transform="translate(1046, 0)">
              <g id="Group" transform="translate(0, 49)">
                <path
                  d="M80,16.5396654 L80,24.8094489 L77.3088583,24.8094489 L77.3088583,23.4540355 C76.3959647,24.6456693 74.9446851,25.0897638 73.7279527,25.0897638 C71.2008859,25.0897638 69.7033465,23.5012795 69.7033465,21.5149606 C69.7033465,19.249311 72.2546261,17.4036418 75.3664372,17.4036418 C75.9752954,17.4036418 76.6305118,17.4508858 77.215256,17.6377953 L77.215256,16.8672244 C77.215256,15.6987205 76.5369095,15.0918307 75.1561025,15.0918307 C73.9162403,15.0918307 72.8161418,15.7228347 72.1378938,16.5406496 L70.1018701,15.0456693 C71.2482284,13.7144685 73.2147638,12.780015 75.203445,12.780015 C78.3152561,12.7779528 80,14.1333661 80,16.5396654 Z M77.215256,20.3002953 L77.215256,19.7858268 C76.7472442,19.6220473 76.2561026,19.5758858 75.6472441,19.5758858 C73.5407481,19.5758858 72.4649608,20.4168307 72.4649608,21.4445866 C72.4649608,22.2855315 73.1905514,22.82313 74.2905511,22.82313 C75.7408467,22.82313 77.215256,21.9590551 77.215256,20.3002953 Z"
                  id="Shape"
                ></path>
                <path
                  d="M64.7658464,9.81102364 C64.7658464,8.89973427 65.5619095,8.15223427 66.4737204,8.15223427 C67.3633858,8.15223427 68.1584647,8.89973427 68.1584647,9.81102364 C68.1584647,10.7223425 67.3623031,11.4697835 66.4737204,11.4697835 C65.5619095,11.4697835 64.7658464,10.7223425 64.7658464,9.81102364 Z M65.0234251,24.8094489 L65.0234251,13.1286417 L67.9018702,13.1286417 L67.9018702,24.8094489 L65.0234251,24.8094489 Z"
                  id="Shape"
                ></path>
                <path
                  d="M63.2683071,24.8556103 C62.8234253,24.9491142 62.3554135,24.9721457 61.9347442,24.9721457 C59.8756891,24.9721457 58.9627952,23.9905512 58.9627952,22.0052166 L58.9627952,8.45563978 L61.8411419,8.45563978 L61.8411419,21.6083662 C61.8411419,22.6130906 62.4500985,22.7527559 63.2683071,22.6593504 L63.2683071,24.8556103 Z"
                  id="Path"
                ></path>
                <path
                  d="M57.1371065,19.7869095 L48.5020669,19.7869095 C48.8060041,21.5160433 50.046949,22.6603347 51.6380907,22.6603347 C52.8316931,22.6603347 53.790748,22.1228347 54.3765749,21.2357284 L56.6701774,22.6141733 C55.4997047,24.1795276 53.5814961,25.113878 51.639075,25.113878 C48.4336616,25.113878 45.6489173,22.5680118 45.6489173,18.9228347 C45.6489173,15.2776575 48.4105316,12.755905 51.4992125,12.755905 C55.5459645,12.7548229 57.5346457,15.9790355 57.1371065,19.7869095 Z M48.5956695,17.6136811 L54.3523621,17.6136811 C54.0010827,16.0955709 53.0188977,15.1370079 51.4971459,15.1370079 C50.0700788,15.1380906 48.9932087,16.1186024 48.5956695,17.6136811 Z"
                  id="Shape"
                ></path>
                <polygon
                  id="Path"
                  points="47.8699805 11.1191929 43.0019687 11.1191929 43.0019687 24.8094489 39.9131889 24.8094489 39.9131889 11.1191929 34.9989173 11.1191929 34.9989173 8.45563978 47.8699805 8.45563978"
                ></polygon>
                <path
                  d="M16.6654528,27.0330709 C11.4156496,29.1579725 8.41109254,28.4808071 6.75054133,27.1559055 L6.72634844,27.1370079 C9.29657482,29.6965552 12.2264764,31.4971457 14.7956693,31.4971457 C19.2945866,31.4971457 25.4541339,26.0483268 28.3692914,17.5989173 C26.7265748,20.6488189 23.2014764,24.3957677 16.6654528,27.0330709 Z"
                  id="Path"
                ></path>
                <path
                  d="M20.6501969,22.1302166 C24.7411417,19.7617126 28.4260827,15.7448819 29.588189,12.8808071 C29.9184055,10.5102362 29.98563,10.2110236 30.0255906,7.60314961 C30.0309055,7.24199803 30.0087599,6.89554133 29.9751969,6.56377953 C29.7364174,10.3937008 24.7778544,15.2094488 18.2177166,18.3559055 C10.3040354,22.1511811 4.3675,23.1727363 1.71839567,20.278248 C2.63858268,22.0924213 3.24223426,22.9732284 4.2770571,24.3191929 C7.33314962,27.0215551 13.7650591,26.1176181 20.6501969,22.1302166 Z"
                  id="Path"
                ></path>
                <path
                  d="M17.295374,15.6420276 C24.7600394,12.0472441 29.9593504,6.93333663 29.0528544,3.5433071 C27.5647638,0.97742815 24.5528544,0 21.3232284,0 C11.7500984,0 0,6.2992126 0,14.3359252 C0,15.3689961 0.228207677,16.5239174 0.637299214,17.7343504 C2.82052166,20.6267717 9.49638782,19.3973425 17.295374,15.6420276 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TeliaLogo;
