const ImasLogo = ({ fill }) => {
  return (
    <svg
      width="104px"
      height="34px"
      viewBox="0 0 104 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>B7BB85B1-75F2-4EFD-812E-8D5C44E4ABF0</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="104" height="34"></rect>
      </defs>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="landing" transform="translate(-43, -49)">
          <g id="logo" transform="translate(43, 49)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Rectangle"></g>
            <g id="Group-16" mask="url(#mask-2)">
              <g transform="translate(0, -0)">
                <g id="Imas" strokeWidth="1" fill="none" fillRule="evenodd">
                  <polygon
                    id="Path"
                    fill={fill}
                    fillRule="nonzero"
                    points="0 33 0 0 7.63714286 0 7.63714286 33"
                  ></polygon>
                  <path
                    d="M8.63714286,33 L8.63714286,10.346 L15.8281429,10.346 L15.8281429,13.636 C16.3294762,12.4766667 17.1519762,11.5366667 18.2956429,10.816 C19.4393095,10.0953333 20.7788095,9.735 22.3141429,9.735 C25.8861429,9.735 28.2518095,11.1293333 29.4111429,13.918 C30.0378095,12.6646667 30.9934762,11.6541667 32.2781429,10.8865 C33.5628095,10.1188333 35.0198095,9.735 36.6491429,9.735 C42.0071429,9.735 44.6861429,12.8056667 44.6861429,18.947 L44.6861429,33 L37.4481429,33 L37.4481429,20.263 C37.4481429,17.2863333 36.3201429,15.798 34.0641429,15.798 C31.5261429,15.798 30.2571429,17.6466667 30.2571429,21.344 L30.2571429,33 L23.0191429,33 L23.0191429,20.216 C23.0191429,17.2706667 21.9068095,15.798 19.6821429,15.798 C18.4288095,15.798 17.4731429,16.2131667 16.8151429,17.0435 C16.1571429,17.8738333 15.8281429,19.3073333 15.8281429,21.344 L15.8281429,33 L8.63714286,33 Z"
                    id="Path"
                    fill={fill}
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M56.3551429,33.611 C53.0651429,33.611 50.4644762,32.4986667 48.5531429,30.274 C46.6418095,28.0493333 45.6861429,25.1823333 45.6861429,21.673 C45.6861429,18.2263333 46.6731429,15.375 48.6471429,13.119 C50.6211429,10.863 53.2218095,9.735 56.4491429,9.735 C58.0471429,9.735 59.4728095,10.0796667 60.7261429,10.769 C61.9794762,11.4583333 62.9194762,12.3983333 63.5461429,13.589 L63.5461429,10.346 L70.6901429,10.346 L70.6901429,33 L63.5461429,33 L63.5461429,29.804 C62.9194762,30.932 61.9559762,31.8485 60.6556429,32.5535 C59.3553095,33.2585 57.9218095,33.611 56.3551429,33.611 Z M58.4231429,27.642 C60.1464762,27.642 61.4781429,27.078 62.4181429,25.95 C63.3581429,24.822 63.8281429,23.3963333 63.8281429,21.673 C63.8281429,19.981 63.3581429,18.571 62.4181429,17.443 C61.4781429,16.315 60.1464762,15.751 58.4231429,15.751 C56.7938095,15.751 55.4856429,16.2993333 54.4986429,17.396 C53.5116429,18.4926667 53.0181429,19.9183333 53.0181429,21.673 C53.0181429,23.4276667 53.5116429,24.8611667 54.4986429,25.9735 C55.4856429,27.0858333 56.7938095,27.642 58.4231429,27.642 Z"
                    id="Shape"
                    fill={fill}
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M82.4061429,33.705 C79.1474762,33.705 76.5468095,33 74.6041429,31.59 C72.6614762,30.18 71.6901429,28.3783333 71.6901429,26.185 L78.8341429,26.185 C78.8341429,26.9683333 79.1788095,27.6106667 79.8681429,28.112 C80.5574762,28.6133333 81.4504762,28.864 82.5471429,28.864 C83.3304762,28.864 83.9649762,28.676 84.4506429,28.3 C84.9363095,27.924 85.1791429,27.407 85.1791429,26.749 C85.1791429,25.715 84.6308095,25.0726667 83.5341429,24.822 L77.9881429,23.412 C76.1394762,22.942 74.7294762,22.143 73.7581429,21.015 C72.7868095,19.887 72.3011429,18.5083333 72.3011429,16.879 C72.3011429,14.7483333 73.1784762,13.0171667 74.9331429,11.6855 C76.6878095,10.3538333 79.0378095,9.688 81.9831429,9.688 C85.2418095,9.688 87.7406429,10.3146667 89.4796429,11.568 C91.2186429,12.8213333 92.0881429,14.5446667 92.0881429,16.738 L84.9911429,16.738 C84.9911429,15.9546667 84.7169762,15.3436667 84.1686429,14.905 C83.6203095,14.4663333 82.8604762,14.247 81.8891429,14.247 C81.1058095,14.247 80.4791429,14.4193333 80.0091429,14.764 C79.5391429,15.1086667 79.3041429,15.563 79.3041429,16.127 C79.3041429,17.1296667 79.9464762,17.8033333 81.2311429,18.148 L86.5891429,19.605 C88.3751429,20.075 89.7773095,20.8505 90.7956429,21.9315 C91.8139762,23.0125 92.3231429,24.3363333 92.3231429,25.903 C92.3231429,28.253 91.4379762,30.1408333 89.6676429,31.5665 C87.8973095,32.9921667 85.4768095,33.705 82.4061429,33.705 Z"
                    id="Path"
                    fill={fill}
                    fillRule="nonzero"
                  ></path>
                </g>
                <path
                  d="M101.451455,24.167898 C101.847488,24.3771837 102.154977,24.6846727 102.364263,25.0807056 C102.571938,25.4767386 102.679801,25.8695517 102.666922,26.9610572 L102.634724,29.656013 C102.620235,30.7459086 102.502713,31.1435514 102.283768,31.544414 C102.070201,31.9409841 101.744992,32.2661931 101.348422,32.4797601 C100.945949,32.6987052 100.549917,32.817837 99.460021,32.8291062 L96.7650651,32.8629139 C95.6751696,32.8757931 95.2807465,32.7679304 94.8847136,32.5602546 C94.4938462,32.3570519 94.1751086,32.0383144 93.971906,31.647447 C93.7626203,31.251414 93.6547576,30.8586009 93.6692466,29.7670955 L93.7014444,27.0705297 C93.7159334,25.9806342 93.8334554,25.5846012 94.0524005,25.1837386 C94.2655366,24.787396 94.5901618,24.4622111 94.9861367,24.2483925 C95.3886092,24.0294475 95.7846421,23.9103156 96.8745376,23.8974365 L99.5711034,23.8652387 C100.660999,23.8507497 101.057032,23.9602222 101.451455,24.167898 Z"
                  id="Path"
                  fill="#FB2221"
                  fillRule="nonzero"
                  transform="translate(98.168, 28.364) rotate(-45) translate(-98.168, -28.364)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ImasLogo;
