const ClosingButton = () => {
  return (
    <svg
      width="56px"
      height="56px"
      viewBox="0 0 56 56"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>6979ED97-1CC7-4C45-BC20-DF22C5A1F3C7</title>
      <g
        id="desktop"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="clients-modal" transform="translate(-1345, -38)" fill="#000000">
          <g id="Group-2" transform="translate(1345, 38)">
            <path
              d="M19.229139,2.77583345e-15 L36.770861,4.32959391e-15 C43.4572515,3.10132389e-15 45.8818983,0.696192084 48.3263428,2.00349528 C50.7707872,3.31079847 52.6892015,5.22921278 53.9965047,7.67365722 C55.3038079,10.1181017 56,12.5427485 56,19.229139 L56,36.770861 C56,43.4572515 55.3038079,45.8818983 53.9965047,48.3263428 C52.6892015,50.7707872 50.7707872,52.6892015 48.3263428,53.9965047 C45.8818983,55.3038079 43.4572515,56 36.770861,56 L19.229139,56 C12.5427485,56 10.1181017,55.3038079 7.67365722,53.9965047 C5.22921278,52.6892015 3.31079847,50.7707872 2.00349528,48.3263428 C0.696192084,45.8818983 3.00926524e-16,43.4572515 -5.17920154e-16,36.770861 L5.17920154e-16,19.229139 C-3.00926524e-16,12.5427485 0.696192084,10.1181017 2.00349528,7.67365722 C3.31079847,5.22921278 5.22921278,3.31079847 7.67365722,2.00349528 C10.1181017,0.696192084 12.5427485,4.00410347e-15 19.229139,2.77583345e-15 Z"
              id="Rectangle"
              fillOpacity="0.03"
            ></path>
            <g id="times" transform="translate(20, 20)" fillRule="nonzero">
              <path
                d="M9.69730304,8.00025001 L14.8254633,2.87208975 L15.8829963,1.8145567 C16.0390012,1.65855183 16.0390012,1.40504391 15.8829963,1.24903903 L14.751461,0.117503672 C14.5954561,-0.0385012032 14.3419482,-0.0385012032 14.1859433,0.117503672 L8.00025001,6.30319697 L1.8145567,0.117003656 C1.65855183,-0.0390012188 1.40504391,-0.0390012188 1.24903903,0.117003656 L0.117003656,1.24853902 C-0.0390012188,1.40454389 -0.0390012188,1.65805181 0.117003656,1.81405669 L6.30319697,8.00025001 L0.117003656,14.1859433 C-0.0390012188,14.3419482 -0.0390012188,14.5954561 0.117003656,14.751461 L1.24853902,15.8829963 C1.40454389,16.0390012 1.65805181,16.0390012 1.81405669,15.8829963 L8.00025001,9.69730304 L13.1284103,14.8254633 L14.1859433,15.8829963 C14.3419482,16.0390012 14.5954561,16.0390012 14.751461,15.8829963 L15.8829963,14.751461 C16.0390012,14.5954561 16.0390012,14.3419482 15.8829963,14.1859433 L9.69730304,8.00025001 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ClosingButton;
