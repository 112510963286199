import Header from "../../components/Header/Header";
import ServicesPageContent from "./ServicesPageContent/ServicesPageContent";
import ServicesPageFooter from "./ServicesPageFooter/ServicesPageFooter";
import { servicesRoutesList } from "../../Constants/ServicesRoutesList";
import { Helmet } from "react-helmet-async";

const ServicesPage = ({ info }) => {
  return (
    <>
      <Helmet>
        <title>Services - Imas.lt</title>
        <meta
          name="description"
          content="Discover our internet and mobile app services at Imas.lt. Let our expertise in crafting innovative solutions help you achieve your digital goals."
        ></meta>
      </Helmet>
      <div className="bg-white text-black">
        <Header />
        <ServicesPageContent info={info} />
        <ServicesPageFooter servicesList={servicesRoutesList} />
      </div>
    </>
  );
};

export default ServicesPage;
