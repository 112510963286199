import Opal from "../../assets/opalHeroSection.png";
import PhoneApp from "../../assets/phoneAppHeroSection.png";
import SmsGatex from "../../assets/SmsGatexHeroSection.png";
import PhoneTransfer from "../../assets/phoneTransferHeroSection.png";
import PressPortal from "../../assets/15minHeroSection.png";
import cardsApp from "../../assets/cardsAppHeroSection.png";
import styles from "../../components/PictureFlow/PictureFlow.module.scss";
import { motion, useScroll, useTransform } from "framer-motion";
import { useEffect, useState } from "react";

const imagesList = [
  { image: Opal, activeClass: styles.opalImage },
  { image: PhoneApp, activeClass: styles.phoneAppImage },
  { image: SmsGatex, activeClass: styles.smsGatexImage },
  { image: PhoneTransfer, activeClass: styles.phoneTransferImage },
  { image: PressPortal, activeClass: styles.pressPortalImage },
  { image: cardsApp, activeClass: styles.cardsAppImage },
];

const PictureFlow = () => {
  const { scrollYProgress } = useScroll();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const x = useTransform(
    scrollYProgress,
    [0, 1],
    [windowWidth <= 768 ? "1%" : "5%", windowWidth <= 768 ? "-600%" : "-300%"]
  );
  return (
    <div className={[styles.containerWrapper, "d-flex"].join(" ")}>
      <div className={[styles.imagesWrapper, "container ps-0"].join(" ")}>
        <motion.div
          className={[styles.imagesContainer, "d-flex align-items-center"].join(
            " "
          )}
          style={{ x }}
        >
          {imagesList.map((image, index) => (
            <img
              key={index}
              src={image.image}
              alt="heroSection"
              className={[
                `${image.activeClass}`,
                "me-3  me-lg-8 me-xl-12",
              ].join(" ")}
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default PictureFlow;
